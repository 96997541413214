// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---node-modules-narative-gatsby-theme-novela-src-templates-article-template-tsx": () => import("./../../../node_modules/@narative/gatsby-theme-novela/src/templates/article.template.tsx" /* webpackChunkName: "component---node-modules-narative-gatsby-theme-novela-src-templates-article-template-tsx" */),
  "component---node-modules-narative-gatsby-theme-novela-src-templates-articles-template-tsx": () => import("./../../../node_modules/@narative/gatsby-theme-novela/src/templates/articles.template.tsx" /* webpackChunkName: "component---node-modules-narative-gatsby-theme-novela-src-templates-articles-template-tsx" */),
  "component---node-modules-narative-gatsby-theme-novela-src-templates-author-template-tsx": () => import("./../../../node_modules/@narative/gatsby-theme-novela/src/templates/author.template.tsx" /* webpackChunkName: "component---node-modules-narative-gatsby-theme-novela-src-templates-author-template-tsx" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-2020-09-22-test-blog-md": () => import("./../../../src/pages/blog/2020-09-22-test-blog.md" /* webpackChunkName: "component---src-pages-blog-2020-09-22-test-blog-md" */),
  "component---src-pages-products-index-md": () => import("./../../../src/pages/products/index.md" /* webpackChunkName: "component---src-pages-products-index-md" */)
}

